export enum Partners {
  AgencyZoom = "Agency Zoom",
}

export enum AgencyCoverage {
  PersonalAutoInsurance = "Auto Insurance",
  PersonalDisabilityInsurance = "Disability Insurance",
  PersonalEarthquakeInsurance = "Earthquake Insurance",
  PersonalFloodInsurance = "Flood Insurance",
  CommercialGeneralLiabilityInsurance = "General Liability Insurance",
  PersonalHealthInsurance = "Health Insurance",
  PersonalHomeownersInsurance = "Homeowners Insurance",
  PersonalIdentityTheftInsurance = "Identity Theft Insurance",
  PersonalLifeInsurance = "Life Insurance",
  PersonalPetInsurance = "Pet Insurance",
  PersonalRentersInsurance = "Renters Insurance",
  PersonalToyInsurance = "Toy Insurance",
  PersonalTravelInsurance = "Travel Insurance",
  PersonalUmbrellaInsurance = "Umbrella Insurance",
  PersonalValuablePropertyInsurance = "Valuable Personal Property Insurance",
  PersonalWeddingInsurance = "Wedding Insurance",
  CommercialPropertyInsurance = "Property Insurance",
  CommercialProfessionalLiabilityInsurance = "Professional Liability Insurance",
  CommercialWorkersCompensationInsurance = "Workers Compensation Insurance",
  CommercialAutoInsurance = "Commercial Auto Insurance",
  CommercialBusinessOwnersPolicyInsurance = "Business Owners Policy Insurance",
  CommercialCyberLiabilityInsurance = "Cyber Liability Insurance",
  CommercialDirectorsAndOfficersInsurance = "Directors and Officers Insurance",
  CommercialConstructionInsurance = "Construction Insurance",
  CommercialMarineInsurance = "Marine Insurance",
  CommercialSuretyBondsInsurance = "Surety Bonds Insurance",
  CommercialProductRecallInsurance = "Product Recall Insurance",
  CommercialProductLiabilityInsurance = "Product Liability Insurance",
  CommercialEnvironmentalAndPollutionLiabilityInsurance = "Environmental and Pollution Liability Insurance",
  CommercialEventCancellationInsurance = "Event Cancellation Insurance",
  CommercialAviationInsurance = "Aviation Insurance",
  CommercialFineArtAndCollectiblesInsurance = "Fine Art and Collectibles Insurance",
  CommercialSportsAndEntertainmentInsurance = "Sports and Entertainment Insurance",
  CommercialAgricultureInsurance = "Agriculture Insurance",
  OtherEmploymentPracticesLiabilityInsurance = "Employment Practices Liability Insurance",
  OtherFidelityAndCrimeInsurance = "Fidelity and Crime Insurance",
  OtherKidnapAndRansomInsurance = "Kidnap and Ransom Insurance",
  OtherLiveScanBackgroundChecks = "Live Scan Background Checks",
  OtherDMVServicesTagRenewalsAndTransfers = "DMV Services: Tag Renewals and Transfers",
  OtherNotaryPublic = "Notary Public",
}

export enum AgencyCoverageSection {
  Personal = "Personal",
  Commercial = "Commercial",
  Other = "Other",
}

export enum AgencyContactInfoType {
  Sales = "Sales",
  Support = "Support",
  Billing = "Billing",
  Claims = "Claims",
  DirectLine = "Direct Line",
  Other = "Other",
}

export enum AgencyContactDepartments {
  Main = "Main",
  Sales = "Sales",
  Support = "Support",
  Billing = "Billing",
  Claims = "Claims",
}

export enum AgencyContactAllowMultiple {
  DirectLine = "Direct Line",
  Other = "Other",
}

export enum AgencyMaxFaqs {
  GailBrain = 100,
  Scripts = 25,
}

export enum AgencySmartSetupSteps {
  Content = "Content",
  Brain = "Brain",
}

export enum AgencyVerificationStatus {
  Pending = "pending",
  Verified = "verified",
  Failed = "failed",
}

export enum SmartSetupStatus {
  Pending = "pending",
  Processed = "processed",
  Completed = "completed",
  Failed = "failed",
}

export enum AgencyDirectoryPhoneType {
  Mobile = "mobile",
  Office = "office",
  Fax = "fax",
}

export enum AgencyDirectoryTransferType {
  Never = "never",
  Always = "always",
  BusinessHours = "business_hours",
  AfterHours = "after_hours",
}

export enum AgencyType {
  Insurance = "insurance",
  Other = "other",
}

export enum VoiceAge {
  YOUNG = "young",
  MIDDLE_AGED = "middle_aged",
}

export enum VoiceGender {
  MALE = "male",
  FEMALE = "female",
}

export enum VoiceAccent {
  AMERICAN = "american",
  AMERICAN_SOUTHERN = "american_southern",
  AFRICAN_AMERICAN = "african_american",
  MEXICAN = "mexican",
  COLOMBIAN = "colombian",
  DOMINICAN = "dominican",
  ECUADORIAN = "ecuadorian",
  ARGENTINIAN = "argentinian",
  BRITISH = "british",
}

export enum AccountLevel {
  SMB = "smb",
  Enterprise = "enterprise",
}

export enum AccountType {
  Insurance = "insurance",
  DebtCollections = "debt_collections",
}

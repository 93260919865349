import {
  AccountLevel,
  AccountType,
  AgencyVerificationStatus,
} from "@/enums/agencies";
import { ScriptType } from "@/enums/campaigns";
import { AgencyRestrictions } from "@/types/agencies";
import { Org } from "@/types/org";

export const DEFAULT_LARGE_RESTRICTION = 1000000;

const AGENCY_RESTRICTIONS_TRIAL: AgencyRestrictions = {
  campaign: {
    maxTotal: 2,
    maxConcurrency: 5,
    maxContacts: 10,
    types: [ScriptType.Phone],
  },
  contacts: {
    maxTotal: 100,
    maxUpload: 10000,
  },
  contactLists: {
    maxTotal: 2,
  },
  sequences: {
    maxTotal: 2,
  },
  redialingRules: {
    maxTotal: 2,
  },
  account: {
    level: AccountLevel.SMB,
    type: [AccountType.Insurance],
  },
  testCases: {
    maxTotal: 10,
  },
  filesUpload: {
    maxTotal: 5,
  },
};

const AGENCY_RESTRICTIONS_PAID: AgencyRestrictions = {
  campaign: {
    maxTotal: -1,
    maxConcurrency: 5,
    maxContacts: 100,
    types: [ScriptType.Phone],
  },
  contacts: {
    maxTotal: 100000,
    maxUpload: 10000,
  },
  contactLists: {
    maxTotal: -1,
  },
  sequences: {
    maxTotal: 20,
  },
  redialingRules: {
    maxTotal: 20,
  },
  account: {
    level: AccountLevel.SMB,
    type: [AccountType.Insurance],
  },
  testCases: {
    maxTotal: 10,
  },
  filesUpload: {
    maxTotal: 20,
  },
};

const AGENCY_RESTRICTIONS_VERIFIED: AgencyRestrictions = {
  campaign: {
    maxTotal: -1,
    maxConcurrency: 5,
    maxContacts: 1000,
    types: [ScriptType.Phone],
  },
  contacts: {
    maxTotal: 1000000,
    maxUpload: 20000,
  },
  contactLists: {
    maxTotal: -1,
  },
  sequences: {
    maxTotal: 100,
  },
  redialingRules: {
    maxTotal: 100,
  },
  account: {
    level: AccountLevel.SMB,
    type: [AccountType.Insurance],
  },
  testCases: {
    maxTotal: 10,
  },
  filesUpload: {
    maxTotal: 20,
  },
};

export const GetRestrictions = (
  org: Org | null,
  allowOverride = true
): AgencyRestrictions => {
  if (org && org.details?.restrictions && allowOverride) {
    let restrictions = org.details.restrictions as AgencyRestrictions;

    if (restrictions.account === undefined) {
      restrictions.account = {
        level: AccountLevel.SMB,
        type: [AccountType.Insurance],
      };
    }

    if (restrictions.campaign.types === undefined) {
      restrictions.campaign.types = [ScriptType.Phone];
    }

    if (restrictions.sequences === undefined) {
      restrictions.sequences = {
        maxTotal: org.services?.stripeCustomerId ? 5 : 2,
      };
    }

    if (restrictions.redialingRules === undefined) {
      restrictions.redialingRules = {
        maxTotal: org.services?.stripeCustomerId ? 5 : 2,
      };
    }

    if (restrictions.testCases === undefined) {
      restrictions.testCases = {
        maxTotal: 10,
      };
    }

    if (restrictions.filesUpload === undefined) {
      restrictions.filesUpload = {
        maxTotal: org.services?.stripeCustomerId ? 20 : 5,
      };
    }

    if (restrictions.account.level === AccountLevel.Enterprise) {
      restrictions.filesUpload = {
        maxTotal: 100,
      };
    }

    return restrictions;
  }

  let defaultRestrictions = AGENCY_RESTRICTIONS_TRIAL;

  if (org && (!!org.services?.stripeCustomerId || org.isDemo)) {
    defaultRestrictions =
      org.details?.agencyVerificationStatus ==
        AgencyVerificationStatus.Verified || org.isDemo
        ? AGENCY_RESTRICTIONS_VERIFIED
        : AGENCY_RESTRICTIONS_PAID;
  }

  return defaultRestrictions;
};

export const GetRestrictionText = (
  restriction: number,
  defaultMax?: string
): string | number => {
  return restriction >= 0
    ? restriction
    : defaultMax || DEFAULT_LARGE_RESTRICTION;
};

export const GetRestrictionValue = (restriction: number): number => {
  return restriction >= 0 ? restriction : DEFAULT_LARGE_RESTRICTION;
};

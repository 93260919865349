"use client";

import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useContext, useEffect, useState } from "react";
import { decodeJwt } from "jose";
import posthog from "posthog-js";
import { apiLive } from "@/utils/common/api";
import { AgencyStatusResponse } from "@/types/agencies";
import { SubscriptionStatus } from "@/enums/stripe";
import { IsAdmin } from "@/utils/common/admin";
import FancyLoader from "@/components/common/fancyLoader";
import { useRouter } from "next/navigation";
import { ClearAllCache } from "@/utils/common/cache";
import { UserRoles } from "@/enums/user";
import { GetRole } from "@/utils/common/auth";
import { GetRestrictions } from "@/utils/common/restrictions";

export type AuthContext = {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  signup: () => Promise<void>;
  getToken: () => Promise<string | undefined>;
  renewToken: () => Promise<void>;
  renewStatus: () => Promise<void>;
  switchOrg: (orgId: string) => Promise<void>;
  requestWithAuth: (init?: RequestInit) => Promise<RequestInit>;
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User | undefined;
  subscription: AgencyStatusResponse;
  isFreeTrial: boolean;
  role: UserRoles;
};

export type UserOrg = {
  id: string;
  name: string;
  display_name: string;
};

export type User = {
  id: string;
  name: string;
  email: string;
  orgId: string | null;
  agencyId: string;
  img?: string;
  given_name?: string;
  family_name?: string;
  user_orgs: UserOrg[];
};

export const AuthContext = createContext<AuthContext | undefined>(undefined);

export const useAuthContext = () => useContext(AuthContext)!;

let userStatusVerifiedToken: string | null | undefined = null;

const useAuth = (): AuthContext => {
  const { push } = useRouter();
  const {
    isLoading: isLoadingAuth0,
    loginWithRedirect,
    logout: logoutAuth0,
    getAccessTokenSilently,
    user: auth0User,
  } = useAuth0();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [agencyStatus, setAgencyStatus] = useState<
    AgencyStatusResponse | null | undefined
  >(undefined);
  const [currentToken, setCurrentToken] = useState<string | undefined>();
  const [identifiedUserId, setIdentifiedUserId] = useState<
    string | undefined
  >();
  const [role, setRole] = useState<UserRoles>(UserRoles.Admin);

  const login = () => {
    const url = window.location.href;
    const inviteMatches = url.match(/invitation=([^&]+)/);
    const orgMatches = url.match(/organization=([^&]+)/);
    if (inviteMatches && orgMatches) {
      return loginWithRedirect({
        authorizationParams: {
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
          redirect_uri: url,
          organization: orgMatches[1],
          invitation: inviteMatches[1],
        },
      });
    }

    return loginWithRedirect({
      authorizationParams: {
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
        redirect_uri: url,
      },
    });
  };

  const signup = () => {
    const url = window.location.href;
    return loginWithRedirect({
      authorizationParams: {
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
        redirect_uri: url,
        screen_hint: "signup",
      },
    });
  };

  const logout = async () => {
    ClearAllCache();
    await logoutAuth0();
    setIsAuthenticated(false);
    setUser(undefined);
    posthog.reset();
  };

  const switchOrg = async (orgId: string) => {
    await loginWithRedirect({
      authorizationParams: {
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
        organization: orgId,
      },
    });
  };

  const renewStatus = async () => {
    try {
      await fetchAgencyStatus();
    } catch (ex) {
      console.error("Failed to renew agency status", ex);
    }
  };

  const renewToken = async () => {
    try {
      const newToken = await getAccessTokenSilently({
        detailedResponse: true,
        authorizationParams: {
          ignoreCache: true,
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
          redirect_uri: window.location.origin,
        },
        cacheMode: "off",
      });

      const payload = decodeJwt(newToken.access_token);

      if (auth0User) {
        setUser({
          id: auth0User.sub!,
          email: auth0User.email!,
          name: auth0User.name ?? auth0User.email!,
          orgId: (payload.org_id as string | undefined) ?? null,
          agencyId: (payload.agency_id as string) ?? "",
          img: auth0User.picture,
          given_name: (payload.given_name as string) ?? "",
          family_name: (payload.family_name as string) ?? "",
          user_orgs: (payload.user_orgs as any[]).map((org: any) => ({
            id: org.id,
            name: org.name,
            display_name: org.display_name,
          })),
        });
      }
    } catch (ex) {
      const err = ex as Error;
      console.error(`Failed to renew token: ${err?.message}`);
      setIsAuthenticated(false);
      setUser(undefined);
    }
  };

  const getToken = async () => {
    try {
      // Try to get a token from the cache. The sdk may return the
      // cached token even if it is expired.
      const cachedTokens = await getAccessTokenSilently({
        detailedResponse: true,
        authorizationParams: {
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
          redirect_uri: window.location.origin,
        },
        cacheMode: "on",
      });

      // Verify the token isn't expired
      const payload = decodeJwt(cachedTokens.access_token);
      const epochSecondsNow = Math.floor(Date.now() / 1000);
      if (payload.exp! > epochSecondsNow) {
        setIsAuthenticated(true);
        setCurrentToken(cachedTokens.access_token);
        if (auth0User) {
          setUser({
            id: auth0User.sub!,
            email: auth0User.email!,
            name: auth0User.name ?? auth0User.email!,
            orgId: (payload.org_id as string | undefined) ?? null,
            agencyId: (payload.agency_id as string) ?? "",
            img: auth0User.picture,
            given_name: (payload.given_name as string) ?? "",
            family_name: (payload.family_name as string) ?? "",
            user_orgs: (payload.user_orgs as any[]).map((org: any) => ({
              id: org.id,
              name: org.name,
              display_name: org.display_name,
            })),
          });
        }
        return cachedTokens.access_token;
      }

      // If the token is expired, make a request to Auth0 to get
      // a new identity token using the stored refresh token.
      const freshTokens = await getAccessTokenSilently({
        detailedResponse: true,
        authorizationParams: {
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
          redirect_uri: window.location.origin,
        },
        cacheMode: "off",
      });

      setIsAuthenticated(true);
      setCurrentToken(freshTokens.access_token);
      const newPayload = decodeJwt(freshTokens.access_token);

      if (auth0User) {
        setUser({
          id: auth0User.sub!,
          email: auth0User.email!,
          name: auth0User.name ?? auth0User.email!,
          orgId: (payload.org_id as string | undefined) ?? null,
          agencyId: (newPayload.agency_id as string) ?? "",
          img: newPayload.picture as string | undefined,
          given_name: (payload.given_name as string) ?? "",
          family_name: (payload.family_name as string) ?? "",
          user_orgs: (payload.user_orgs as any[]).map((org: any) => ({
            id: org.id,
            name: org.name,
            display_name: org.display_name,
          })),
        });
      }
      setCurrentToken(freshTokens.access_token);
      return freshTokens.access_token;
    } catch (ex) {
      const err = ex as Error;
      console.error(
        `Failed to retrieve a valid identity token: ${err?.message}`
      );
      setIsAuthenticated(false);
      setUser(undefined);
      return undefined;
    }
  };

  const requestWithAuth = async (existing?: RequestInit) => {
    const init: RequestInit = existing != null ? existing : { headers: {} };

    const accessToken = await getToken();
    if (!accessToken) {
      return init;
    }

    init.headers = {
      ...init.headers,
      Authorization: `Bearer ${accessToken}`,
    };

    return init;
  };

  useEffect(() => {
    if (isLoadingAuth0) {
      setIsLoading(true);
      return;
    }
    const effect = async () => {
      await getToken();
      setIsLoading(false);
    };
    effect();
  }, [isLoadingAuth0]);

  const identifyUser = async (
    user: User,
    agencyStatus?: AgencyStatusResponse | null
  ) => {
    if (user.id === identifiedUserId) {
      return;
    }

    setIdentifiedUserId(user.id);

    posthog.identify(user.id, {
      email: user.email,
      name: user.name,
      orgId: user.orgId,
      agencyId: user.agencyId,
      subscription: agencyStatus,
      isFreeTrial: agencyStatus?.status === SubscriptionStatus.none,
    });
    posthog.setPersonPropertiesForFlags({
      name: user.name,
      email: user.email,
      orgId: user.orgId,
      agencyId: user.agencyId,
      subscription: agencyStatus,
      isFreeTrial: agencyStatus?.status === SubscriptionStatus.none,
    });
    posthog.group("agency", user.agencyId ? user.agencyId : "onboarding");
    posthog.group(
      "subscription",
      agencyStatus ? agencyStatus.status : SubscriptionStatus.none
    );

    (window as any).Canny &&
      (window as any).Canny("identify", {
        appID: "6679b7fcbb6ee9e80d74a55c",
        user: {
          email: user.email,
          name: user.name,
          id: user.id,
          companies: [
            {
              id: user.agencyId,
              name: user.agencyId,
              customFields: {
                orgId: user.orgId,
                plan: agencyStatus?.status,
                isFreeTrial: agencyStatus?.status === SubscriptionStatus.none,
              },
            },
          ],
        },
      });
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    identifyUser(user, agencyStatus);
  }, [user, agencyStatus]);

  useEffect(() => {
    if (isLoading) {
      document.title = "GAIL | Loading...";
    } else if (!user) {
      document.title = "GAIL | Login";
    }
  }, [user, isLoading]);

  const fetchAgencyStatus = async () => {
    try {
      const newAgencyStatus = await apiLive<AgencyStatusResponse>(
        getToken,
        "/api/settings/agency/status"
      );

      setAgencyStatus(newAgencyStatus);

      if (
        !newAgencyStatus?.hasUsage &&
        !window.sessionStorage.getItem("hasTakenUserToStartPage")
      ) {
        window.sessionStorage.setItem("hasTakenUserToStartPage", "true");

        if (
          window.location.pathname !== "/start" &&
          window.location.pathname !== "/upgrade" &&
          window.location.pathname.length < 15 &&
          role !== UserRoles.Integration
        ) {
          push("/start");
        }
      }
    } catch (ex) {
      console.error("Failed to fetch agency status", ex);
      setAgencyStatus({
        status: SubscriptionStatus.trialing,
        canAccess: true,
        restrictions: GetRestrictions(null),
        flags: {
          needs_signing: false,
        },
        hasUsage: false,
      });
    }
  };

  useEffect(() => {
    if (
      user &&
      user.agencyId &&
      user.orgId &&
      userStatusVerifiedToken !== currentToken
    ) {
      userStatusVerifiedToken = currentToken;

      fetchAgencyStatus();

      if (currentToken) {
        setRole(GetRole(currentToken));
      } else {
        setRole(UserRoles.User);
      }
    }
  }, [currentToken, user]);

  return {
    login,
    signup,
    logout,
    getToken,
    renewToken,
    renewStatus,
    switchOrg,
    requestWithAuth,
    user,
    isAuthenticated,
    isLoading,
    subscription: agencyStatus
      ? agencyStatus
      : {
          status: SubscriptionStatus.active,
          canAccess: true,
          restrictions: GetRestrictions(null),
          flags: {
            needs_signing: false,
          },
          hasUsage: true,
        },
    isFreeTrial: agencyStatus?.status === SubscriptionStatus.none,
    role,
  };
};

export const AuthContextProvider = ({
  children,
  isAdmin,
}: {
  children: React.ReactNode;
  isAdmin?: boolean;
}) => {
  const auth = useAuth();

  if (isAdmin) {
    if (auth.isLoading) {
      return <FancyLoader />;
    }

    if (!IsAdmin(auth.user)) {
      return <div>You must be an admin to access this page.</div>;
    }
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
